@import './colors.scss'; 

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 30px;
    left: 25px;
    top: 20px;
}
    
/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: $curious_blue;
}
    
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: $danube;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 28px;
    width: 28px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    width: 200px !important;
}

/* General sidebar styles */
.bm-menu {
    background: $white;
    padding: 2.5em 0.5em 0;
    font-size: 1.15em;
    overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    overflow: hidden;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
    padding: 10px 0px;
    color: $curious_blue;
    text-decoration: none;
    font-size: 18px;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}