@import '../../styles/colors.scss';

.money_transfer_wrapper {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

    .display_none {
        display: none;
    }

    .visibility_hidden {
        visibility: hidden;
    }

    .no_results {
        color: $bahama_blue;
        font-size: 24px;
        display: flex;
        align-content: space-around;
        align-items: center;
        width: 260px;
    }

    .menu_title {
        display: none;
    }

    .title {
        font-size: 20px;
        color: $bahama_blue;
        font-weight: bold;
    }

    :global(.MuiLinearProgress-root) {
        border-radius: 9px;
        height: 15px;
        border-radius: 10px;
    }

    :global(.MuiLinearProgress-barColorPrimary) {
        background-color: $bahama_blue;
    }

    :global(.MuiLinearProgress-colorPrimary) {
        background-color: rgba($silver_chalice, 0.5)
    }

    .label_progress_bar {
        font-size: 25px;
        font-weight: bold;
        color: $bahama_blue;
    }

    .question {
        font-size: 24px;
        color: $bahama_blue;
        padding-top: 20px;
    }

    .progress_bar_width {
        width: 50%;
    }

    :global(.MuiTextField-root) {
        margin-bottom: 25px;
    }

    :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
        border-color: $bahama_blue;
    }

    :global(.MuiOutlinedInput-root) {
        border-radius: 10px;
    }

    :global(.MuiOutlinedInput-input) {
        color: $bahama_blue;
        height: 25px;
        font-weight: bold;
        font-size: 22px;
    }

    :global(.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline) {
        border-color: $bahama_blue;
    }

    :global(.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline) {
        border-color: rgba($bahama_blue, 0.5);
    }

    :global(.MuiStepLabel-label) {
        display: none;
    }

    .money_transfer_first_step {
        padding-top: 20px;
        display: flex;

        .label {
            font-size: 24px;
            color: $bahama_blue;
            padding-bottom: 20px;
        }

        .input {
            width: 300px;
        }

        .calc_section {
            margin-left: 30px;
            padding-top: 20px;

            .labels_wraper {
                display: flex;
                width: 450px;

                .title_calc {
                    font-size: 19px;
                    width: 50%;
                    color: $silver_chalice;
                    text-align: left;
                    margin-bottom: 5px;
                    margin-top: 5px;
                }

                .value_calc {
                    font-size: 19px;
                    width: 50%;
                    font-weight: bold;
                    color: $bahama_blue;
                    text-align: right;
                    margin-bottom: 5px;
                    margin-top: 5px;
                }
            }

            .border {
                border-bottom: 3px solid $bahama_blue;
            }
        }
    }

    .note_info {
        color: $silver_chalice;
        font-style: italic;
    }

    .money_transfer {
        padding-top: 20px;

        .label {
            font-size: 22px;
            color: $bahama_blue;
            padding-bottom: 20px;
        }

        .input {
            width: 300px;
        }

        .calc_section {
            margin-left: 30px;
            padding-top: 20px;

            .labels_wraper {
                display: flex;
                width: 450px;

                .title_calc {
                    font-size: 19px;
                    width: 50%;
                    color: $silver_chalice;
                    text-align: left;
                    margin-bottom: 5px;
                    margin-top: 5px;
                }

                .value_calc {
                    font-size: 19px;
                    width: 50%;
                    font-weight: bold;
                    color: $bahama_blue;
                    text-align: right;
                    margin-bottom: 5px;
                    margin-top: 5px;
                }
            }

            .border {
                border-bottom: 3px solid $bahama_blue;
            }
        }

    }

    .button_section {
        display: flex;
        gap: 450px;
        padding-top: 10px;

        button {
            height: 40px;
            width: 160px;
            border-radius: 30px;
            border: none;
            background-color: $bahama_blue;
            color: #FFFFFF;
            font-size: 18px;
            line-height: 22px;
            font-weight: bold;
            padding: 10px 0px;
            cursor: pointer;
            letter-spacing: 1px;
        }

        .disabled {
            opacity: 0.5;
            pointer-events: none;
        }

    }

    .second_step {
        text-align: left;

        .input {
            width: 500px;

            :global(.MuiTextField-root) {
                width: 500px;
            }

            :global(.MuiOutlinedInput-input) {
                color: $bahama_blue;
                height: 18px;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                padding: 14px 14px;
            }
        }
    }

    .third_step {
        .input {
            padding-top: 20px;
            width: 600px;

            :global(.MuiOutlinedInput-input) {
                color: $bahama_blue;
                height: 18px;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                padding: 14px 14px;
            }
        }

        .search_icon {
            color: $bahama_blue;
        }

        .recipients {
            .title {
                font-weight: normal;
                font-size: 24px;

                padding-top: 30px;
                padding-bottom: 20px;
            }
        }

        .box_section {
            display: flex;
            flex-wrap: wrap;
            width: 600px;
            height: 200px;
            margin: auto;
            overflow: auto;
            padding: 20px;
            -ms-overflow-style: none;
            /* Internet Explorer 10+ */
            scrollbar-width: none;

            /* Firefox */
            .box_create_new {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                border: solid 1px $bahama_blue;
                height: 160px;
                min-width: 160px;
                border-radius: 10px;
                margin-left: 10px;
                margin-bottom: 10px;
                cursor: pointer;
                background-color: rgba($bahama_blue, 0.05);
                margin-right: 10px;

                .label {
                    color: $bahama_blue;
                    font-weight: bold;
                }
            }

            .box_selected {
                background-color: #c7e5ff;
                border: solid 3px $bahama_blue;
            }

            .box_not_selected {
                background-color: rgba($bahama_blue, 0.05);
                border: solid 1px $bahama_blue;
            }

            .box_recipient {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 160px;
                min-width: 160px;
                border-radius: 10px;
                cursor: pointer;
                margin-left: 10px;
                margin-right: 10px;
                margin-bottom: 10px;

                .edit_icon_wrapper {
                    position: relative;

                    .edit_icon_position {
                        position: absolute;
                        top: 5px;
                        left: 55px;
                        background-color: $bahama_blue;
                    }
                }

                .icon_section {
                    margin: 10px 0px 5px 0px;
                }

                .recipient_name {
                    color: $bahama_blue;
                    padding-bottom: 10px;
                    text-transform: capitalize;
                    width: 150px;
                }

                .recipient_location {
                    color: $bahama_blue;
                    font-size: 15px;
                    text-transform: capitalize;
                }

                .recipient_bank {
                    color: $bahama_blue;
                    font-size: 15px;
                    text-transform: capitalize;
                }
            }
        }

        .box_section::-webkit-scrollbar {
            display: none;
            /* Safari and Chrome */
        }
    }

    .fourth_step {
        width: 100%;

        .boxes_section {
            display: flex;
        }

        .wrapper_payment {
            border: solid 1px $bahama_blue;
            width: 47%;
            margin: auto;
            margin-top: 20px;
            border-radius: 10px;

            .title_icon_wrapper {
                display: flex;
            }

            .help_icon {
                height: 20px;
                width: 20px;
                color: $bahama_blue;
                margin-left: 10px;
                padding-top: 15px;
                padding-bottom: 15px;
            }

            .info_section {
                padding: 15px;

                .big_title {
                    font-size: 20px;
                    color: $bahama_blue;
                    text-align: center;
                    font-weight: bold;
                    padding-top: 15px;
                    padding-bottom: 15px;
                }

                .payment_subtitle {
                    font-size: 18px;
                    color: #A0A0A0;
                }

                .information {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding-bottom: 15px;

                    .title {
                        font-size: 18px;
                        color: $bahama_blue;
                        text-align: left;
                        width: 25%;
                        padding-top: 0px;
                    }

                    .text {
                        text-align: left;
                        font-size: 18px;
                        color: #A0A0A0;
                        width: 65%;
                    }
                }
            }

            :global(.MuiFab-secondary) {
                background-color: $bahama_blue;
            }

            .file_upload_wrapper {
                display: flex;
                width: 25%;

                span {
                    font-size: 14px;
                    width: 185px;
                }
            }

            .autocomplete_container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding-bottom: 15px;
            }

            .input_label {
                font-size: 18px;
                color: $bahama_blue;
                text-align: left;
                padding-top: 5px;
                font-weight: bold;
                width: 65%;
            }

            .file_upload {
                margin-left: 18px;
                display: flex;
                flex-direction: column;

                .file_upload_name {
                    display: flex;
                    color: $prussian_blue;
                    margin-top: 5px;

                    span {
                        margin-left: 15px;
                    }
                }
            }

        }

        .wrapper {
            border: solid 1px $bahama_blue;
            width: 47%;
            margin: auto;
            margin-top: 20px;
            border-radius: 10px;

            .info_section {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 5px;
                padding-top: 15px;
                width: 100%;

                .label {
                    font-size: 18px;
                    color: $bahama_blue;
                    width: 30%;
                    text-align: left;
                    font-weight: bold;
                }

                .icon_section {
                    width: 10%;
                }

                .information {
                    width: 40%;
                    margin-left: 30px;
                    text-align: left;

                    .full_name {
                        font-size: 18px;
                        color: $silver_chalice;
                        text-transform: capitalize;
                        ;
                    }

                    .location {
                        font-size: 16px;
                        color: $silver_chalice;
                        text-transform: capitalize;
                        ;
                    }

                    .email {
                        font-size: 16px;
                        color: $silver_chalice;
                    }
                }
            }
        }

        .button_section {
            margin-right: auto;
            margin-left: auto;
            width: 769px;

            button {
                margin-top: 10px;
            }
        }
    }

    .fifth_step {
        text-align: left;
        padding-top: 50px;

        .title {
            font-size: 35px;
            color: $bahama_blue;
            padding-bottom: 30px;
        }

        .subtitle {
            font-size: 24px;
            color: $bahama_blue;
            padding-bottom: 50px;
        }
    }
}


// Smaller screens (smaller than 640px)
@media only screen and (max-width: 640px) {
    :global(.MuiMenuItem-root) {
        font-size: 14px !important;
        white-space: pre-wrap;
    }

    .money_transfer_wrapper {
        padding-top: 70px;

        :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
            border-color: $bahama_blue;
        }

        :global(.MuiOutlinedInput-root) {
            border-radius: 10px;
        }

        :global(.MuiOutlinedInput-input) {
            color: $bahama_blue;
        }

        :global(.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline) {
            border-color: $bahama_blue;
        }

        :global(.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline) {
            border-color: rgba($bahama_blue, 0.5);
        }

        .menu_wrapper {
            background-color: $white;
            height: 60px;
            width: 100%;
            position: fixed;
            top: 0px;
            z-index: 99;
        }

        .menu_title {
            padding-left: 25px;
            font-size: 21px;
            font-weight: bold;
            color: $bahama_blue;
            padding-top: 23px;
            text-align: center;
            position: fixed;
            top: 0px;
            z-index: 99;
        }

        .title {
            font-size: 20px;
        }

        :global(.MuiLinearProgress-root) {
            border-radius: 9px;
            height: 15px;
            border-radius: 10px;
        }

        .label_progress_bar {
            font-size: 25px;
        }

        .question {
            font-size: 20px;
        }

        .progress_bar_width {
            width: 85%;
        }

        :global(.MuiTextField-root) {
            margin-bottom: 25px;
        }

        :global(.MuiOutlinedInput-root) {
            border-radius: 10px;
        }

        :global(.MuiOutlinedInput-input) {
            color: $bahama_blue;
            height: 15px;
        }

        :global(.MuiStepLabel-label) {
            display: none;
        }

        .money_transfer_first_step {
            padding-top: 20px;
            display: flex;
            flex-direction: column;

            .label {
                font-size: 23px;
                color: $bahama_blue;
                padding-bottom: 10px;
            }

            .input {
                width: 300px;
            }

            .calc_section {
                margin-left: 20px;
                padding-top: 10px;

                .labels_wraper {
                    display: flex;
                    width: 267px;

                    .title_calc {
                        font-size: 16px;
                        width: 50%;
                        color: $silver_chalice;
                        text-align: left;
                        margin-bottom: 5px;
                        margin-top: 5px;
                    }

                    .value_calc {
                        font-size: 16px;
                        width: 50%;
                        font-weight: bold;
                        color: $bahama_blue;
                        text-align: right;
                        margin-bottom: 5px;
                        margin-top: 5px;
                    }
                }

                .border {
                    border-bottom: 3px solid $bahama_blue;
                }
            }
        }

        .note_info {
            font-size: 13px;
            color: $silver_chalice;
            font-style: italic;
        }

        .money_transfer {
            padding-top: 50px;

            .label {
                font-size: 21px;
            }

            .input {
                width: 300px;
            }
        }

        .button_section {
            gap: 60px;
            justify-content: center;

            button {
                height: 40px;
                width: 120px;
                font-size: 14px;
                line-height: 14px;
            }
        }

        .second_step {
            text-align: left;

            .input {
                width: 295px;

                :global(.MuiTextField-root) {
                    width: 295px;
                }

                :global(.MuiOutlinedInput-input) {
                    color: $bahama_blue;
                    height: 14px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    padding: 14px;
                }
            }
        }

        .third_step {
            :global(.MuiOutlinedInput-input) {
                color: $bahama_blue;
                height: 25px;
                font-weight: bold;
                font-size: 17px;
                line-height: 28px;
            }

            :global(.MuiOutlinedInput-input) {
                color: $bahama_blue;
                height: 14px;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                padding: 14px;
            }

            .input {
                width: 300px;
            }

            .recipients {
                .title {
                    text-align: center;
                    font-size: 21px;
                }
            }

            .box_section {
                display: flex;
                justify-content: center;
                width: 250px;
                align-items: center;
                margin: 0px;

                .box_create_new {
                    height: 150px;
                    min-width: 160px;
                    border-radius: 10px;
                    margin-bottom: 15px;

                    .label {
                        font-weight: bold;
                    }
                }

                .box_recipient {
                    height: 150px;
                    min-width: 160px;
                    margin-bottom: 15px;

                    .icon_section {
                        margin: 10px 0px 5px 0px;
                    }

                    .recipient_name {
                        width: 150px;
                        padding-bottom: 10px;
                        text-transform: capitalize;
                    }

                    .recipient_location {
                        font-size: 15px;
                    }

                    .recipient_bank {
                        font-size: 15px;
                    }
                }
            }
        }

        .fourth_step {
            .boxes_section {
                display: block;
            }

            .wrapper {
                width: auto;
                margin: 10px;

                .info_section {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 25px;
                    padding-top: 15px;
                    width: 100%;

                    .label {
                        font-size: 15px;
                    }

                    .icon_section {
                        width: 10%;
                    }

                    .information {
                        margin-left: 17px;

                        .full_name {
                            font-size: 15px;
                        }

                        .location {
                            font-size: 13px;
                        }

                        .email {
                            font-size: 13px;
                        }
                    }
                }
            }

            .wrapper_payment {
                width: auto;
                margin: 10px;

                .title_icon_wrapper {
                    display: flex;
                }

                .help_icon {
                    height: 20px;
                    width: 20px;
                    color: $bahama_blue;
                    margin-left: 10px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                }

                .info_section {
                    padding: 15px;

                    .big_title {
                        font-size: 15px;
                        color: #22A8DD;
                        text-align: center;
                        font-weight: bold;
                        padding-top: 15px;
                        padding-bottom: 15px;
                    }

                    .payment_subtitle {
                        font-size: 15px;
                        color: #A0A0A0;
                    }

                    .information {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        padding-bottom: 15px;

                        .title {
                            font-size: 15px;
                            color: $bahama_blue;
                            text-align: left;
                            width: 25%;
                            padding-top: 0px;
                        }

                        .text {
                            text-align: left;
                            font-size: 15px;
                            color: #A0A0A0;
                            width: 65%;
                        }
                    }
                }

                :global(.MuiFab-secondary) {
                    background-color: $bahama_blue;
                }

                .file_upload_wrapper {
                    display: block;

                    span {
                        font-size: 14px;
                        width: 155px;
                    }
                }

                .autocomplete_container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding-bottom: 15px;
                }

                .input_label {
                    font-size: 15px;
                    padding-right: 0px;
                    padding-bottom: 10px;
                    padding-left: 8px;
                }

                .file_upload {
                    margin-left: 18px;
                    display: flex;
                    flex-direction: column;

                    .file_upload_name {
                        display: flex;
                        color: $prussian_blue;
                        margin-top: 5px;

                        span {
                            margin-left: 15px;
                        }
                    }
                }

            }

            .button_section {
                width: auto;

            }
        }

        .fifth_step {
            .title {
                font-size: 22px;
                padding-bottom: 30px;
                margin: 0px 10px;
            }

            .subtitle {
                font-size: 16px;
                margin: 0px 10px;
            }
        }
    }
}


// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .money_transfer_wrapper {
        .title {
            margin-top: 20px;
        }

        :global(.MuiLinearProgress-root) {
            border-radius: 9px;
            height: 15px;
            border-radius: 10px;
        }

        .question {
            font-size: 24px;
        }

        .progress_bar_width {
            width: 70%;
        }

        .money_transfer {
            padding-top: 30px;

            .label {
                font-size: 22px;
                padding-bottom: 20px;
            }
        }

        .button_section {
            gap: 230px;

            button {
                height: 35px;
                width: 150px;
                font-size: 15px;
                line-height: 15px;
                padding: 5px 0px;
            }

        }

        .money_transfer_first_step {
            padding-top: 20px;
            display: flex;

            .label {
                font-size: 23px;
                color: $bahama_blue;
                padding-bottom: 10px;
            }

            .input {
                width: 190px;
            }

            .calc_section {
                margin-left: 20px;
                padding-top: 10px;

                .labels_wraper {
                    display: flex;
                    width: 267px;

                    .title_calc {
                        font-size: 16px;
                        width: 50%;
                        color: $silver_chalice;
                        text-align: left;
                        margin-bottom: 5px;
                        margin-top: 5px;
                    }

                    .value_calc {
                        font-size: 16px;
                        width: 50%;
                        font-weight: bold;
                        color: $bahama_blue;
                        text-align: right;
                        margin-bottom: 5px;
                        margin-top: 5px;
                    }
                }

                .border {
                    border-bottom: 3px solid $bahama_blue;
                }
            }
        }

        .second_step {
            text-align: left;

            .input {
                width: 430px;
            }
        }

        .third_step {
            .input {
                width: 600px;
            }

            .recipients {
                .title {
                    font-size: 22px;
                    padding-top: 30px;
                    padding-bottom: 20px;
                    margin-left: 15px
                }
            }

            .box_section {
                width: 535px;
                justify-content: center;
            }
        }

        .fourth_step {
            .boxes_section {
                display: block;
            }

            .wrapper {
                width: auto;
                margin: 10px;

                .info_section {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 25px;
                    padding-top: 15px;
                    width: 100%;

                    .label {
                        font-size: 15px;
                    }

                    .icon_section {
                        width: 10%;
                    }

                    .information {
                        margin-left: 17px;

                        .full_name {
                            font-size: 15px;
                        }

                        .location {
                            font-size: 13px;
                        }

                        .email {
                            font-size: 13px;
                        }
                    }
                }
            }

            .wrapper_payment {
                width: auto;
                margin: 10px;

                .title_icon_wrapper {
                    display: flex;
                }

                .help_icon {
                    height: 20px;
                    width: 20px;
                    color: $bahama_blue;
                    margin-left: 10px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                }

                .info_section {
                    padding: 15px;

                    .big_title {
                        font-size: 15px;
                        color: #22A8DD;
                        text-align: center;
                        font-weight: bold;
                        padding-top: 15px;
                        padding-bottom: 15px;
                    }

                    .payment_subtitle {
                        font-size: 15px;
                        color: #A0A0A0;
                    }

                    .information {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        padding-bottom: 15px;

                        .title {
                            font-size: 15px;
                            color: #22A8DD;
                            text-align: left;
                            width: 25%;
                            padding-top: 0px;
                        }

                        .text {
                            text-align: left;
                            font-size: 15px;
                            color: #A0A0A0;
                            width: 65%;
                        }
                    }
                }

                :global(.MuiFab-secondary) {
                    background-color: $summer_sky;
                }

                .file_upload_wrapper {
                    display: block;

                    span {
                        font-size: 14px;
                        width: 155px;
                    }
                }

                .autocomplete_container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding-bottom: 15px;
                }

                .input_label {
                    font-size: 15px;
                    padding-right: 0px;
                    padding-bottom: 10px;
                    padding-left: 8px;
                }

                .file_upload {
                    margin-left: 18px;
                    display: flex;
                    flex-direction: column;

                    .file_upload_name {
                        display: flex;
                        color: $prussian_blue;
                        margin-top: 5px;

                        span {
                            margin-left: 15px;
                        }
                    }
                }

            }

            .button_section {
                gap: 475px;
            }
        }

        .fifth_step {
            text-align: left;
            padding-top: 50px;

            .title {
                font-size: 28px;
            }

            .subtitle {
                font-size: 20px;
            }
        }
    }
}