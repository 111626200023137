@import '../../styles/colors.scss';

.about_us_wrapper {
    .header_section {
        padding-bottom: 50px;
        margin: 0px;
        .title {
            color: $bahama_blue;
            font-weight: bold;
            font-size: 36px;
            margin: 26px auto 20px;
        }
        .subtitle {
            color: $silver_chalice;
            font-size: 18px;
            margin: 26px auto 20px;
        }
    }
    .infomation_section {
        display: flex;
        height: 50%;
        width: 50%;
        border: 1px solid $bahama_blue;
        border-radius: 25px;
        padding: 40px 40px 40px 0px;
        background-color: $whisper;
        .img_section {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
        }
        .border_line {
            border-left: 1px solid $bahama_blue;
            height: 450px;
            padding: 20px;
        }
        .text_section{
            flex: 1;
            padding-top: 25px;
            .text_row {
                display: flex;
                flex-direction: column;
                padding: 10px 20px;
                text-align: center;
                color: $silver_chalice;
                .title {
                    padding-bottom: 10px;
                    font-weight: bold;
                }
                .subtitle {
                    background: $bahama_blue;
                    color: $white_smoke;
                    padding: 10px;
                }
            }
        }
    }
}


// Smaller screens (smaller than 640px)
@media only screen and (max-width: 640px) {
    .about_us_wrapper {       
        .menu_wrapper {
            background-color: $white;
            height: 60px;
            width: 100%;
            position: fixed;
            top: 0px;
            z-index: 99999999;
        } 
        .header_section {
            padding-bottom: 0px;
            margin-top: 70px;
            .title {
                font-size: 28px;
                text-align: center;
            }
            .subtitle {
                text-align: center;
                padding: 0px 10px
            }
        }
        .infomation_section {
            display: block;
            width: 100%;
            border: 0px solid white;
            padding: 0px;
            background-color: transparent;
            .img_section {
                text-align: center;
                display: block;
            }
            .border_line {
                display: none;
            }
            .text_section{
                flex: 1;
                padding-top: 25px;
                .text_row {
                    display: flex;
                    flex-direction: column;
                    padding: 10px 20px;
                    text-align: center;
                    color: $silver_chalice;
                    .title {
                        padding-bottom: 10px;
                        font-weight: bold;
                    }
                    .subtitle {
                        background: $bahama_blue;
                        padding: 10px;
                    }
                }
            }
        }
    }
}

// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .about_us_wrapper {
        .header_section {
            padding-bottom: 0px;
            margin: 0px;
            .title {
                font-size: 28px;
                margin: 16px auto 10px;
            }
            .subtitle {
                font-size: 18px;
                margin: 10px auto 20px;
            }
        }
        .infomation_section {
            width: 90%;
            padding: 10px 10px 10px 0px;
            .border_line {
                border-left: 1px solid $bahama_blue;
                height: 400px;
                padding: 15px;
                margin: 20px 0px 20px 0px;
            }
            .text_section{
                padding-top: 30px;
                .text_row {
                    padding: 5px 0px;
                }
            }
        }
    }
    
}