@import '../../styles/colors.scss';

.loading_wrapper {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    :global(.MuiCircularProgress-root) {        
        color: $curious_blue;
    }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
    }
}