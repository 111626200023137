@import "../../styles/colors.scss";

.login_page_wrapper {
    height: 100%;
    display: flex;
    .login_page_right_section {
        flex: 1;
        background-color: ghostwhite;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: cover;
        height: 100%;
        a {
            text-decoration: none;
            cursor: pointer;
        }
        form {
            display: flex;
            flex-direction: column;
            min-width: 333px;
            :global(.MuiTextField-root) {
                margin-bottom: 15px;
                &:nth-of-type(2){
                    margin-bottom: 10px;
                }
            }
            :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline){
                border-color: $bahama_blue;
            }
            :global(.MuiOutlinedInput-root) {
                border-radius: 10px;
            }
            :global(.MuiOutlinedInput-input) {
                color: $gull_gray;
            }
        }
        .login_title {
            color: $bahama_blue;
            font-weight: bold;
            font-size: 36px;
            margin: 26px auto 20px;
            letter-spacing: 1px;
        }
        h4 {
            color: $gull_gray;
            letter-spacing: 1px;
        }
        .links_section {
            display: flex;
            justify-content: space-between;
            font-size: 15px;
            color: $bahama_blue;
            .forgot_password, .privacy_policy {
                margin-bottom: 23px;
                color: $bahama_blue;
                cursor: pointer;
                text-decoration: underline;
            }
        }
        .input {
            margin-bottom: 15px;
        }

        .register_section {
            display: flex;
            justify-content: center;
            font-size: 15px;
            letter-spacing: 1px;
            p {
                margin: 35px 5px 0px 0px;
            }
            .register_question {
                color: $gull_gray;   
            }
            .register_link { 
                margin: 35px 0px 0px 0px;
                color: $bahama_blue;
                font-weight: bold;
                text-decoration: underline;
            }
        }

        .button {
            border-radius: 30px;
            border: none;
            background-color: $bahama_blue;
            color: #FFFFFF;
            font-size: 18px;
            line-height: 22px;
            font-weight: bold;
            padding: 14px 0px;
            cursor: pointer;
            margin-top: 50px;
            letter-spacing: 1px;
        }

    }
    .login_page_left_section {
        flex: 1;
        background: url('../../assets/background2.png') center;
        .header_title {
            color: $white;
            font-weight: bold;
            font-size: 36px;
            margin: 26px 50px 20px;
            letter-spacing: 1px;
        }
        .title_section {
            display: flex;
            justify-content: center;
            height: 70%;
            flex-wrap: wrap;
            flex-grow: 1;
            flex-direction: column;
            align-items: center;
            color: $white;
            text-align: center;
            font-weight: bold;
            letter-spacing: 0.5px;
            .greeting_msg{
                font-size: 40px;
                margin: 26px auto 0px;
            }
            .greeting_title{
                font-size: 60px;
                margin: 26px auto 10px;
                letter-spacing: 5px;
            }
            .secondary_text {
                width: 600px;
                font-size: 15px;
                font-weight: normal;
                letter-spacing: 0px;
                padding-top: 60px;
            }
            hr {
                width: 10%;
                display: block;
                height: 1px;
                border: 0;
                border-top: 10px solid $white;
                margin: 1em 0;
                padding: 0;
            }
        }

    }
}




// Small screens (smaller than 640px)
@media only screen and (max-width: 640px) {
    .login_page_wrapper {
        height: 100%;
        display:unset;
        .login_page_right_section {
            flex: 1;
            background-color: ghostwhite;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-size: cover;
            height: 100%;
            a {
                text-decoration: none;
                cursor: pointer;
            }
            form {
                display: flex;
                flex-direction: column;
                min-width: 300px;
                :global(.MuiTextField-root) {
                    width: 270px;
                    margin: 10px auto;
                    &:nth-of-type(2){
                        margin-bottom: 10px;
                    }
                }
                :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline){
                    border-color: $bahama_blue;
                }
                :global(.MuiOutlinedInput-root) {
                    border-radius: 10px;
                }
                :global(.MuiOutlinedInput-input) {
                    color: $gull_gray;
                }
            }
            .login_title {
                color: $bahama_blue;
                font-weight: bold;
                font-size: 25px;
                margin: 0px;
                letter-spacing: 1px;
            }
            h4 {
                color: $gull_gray;
                letter-spacing: 1px;
            }
            .links_section {
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                color: $bahama_blue;
                margin: 0px 20px;
                .forgot_password, .privacy_policy {
                    margin-bottom: 23px;
                    color: $bahama_blue;
                }
            }
            .input {
                margin-bottom: 15px;
            }
    
            .register_section {
                display: block;
                justify-content: center;
                font-size: 15px;
                letter-spacing: 1px;
                text-align: center;
                p {
                    margin: 35px 5px 0px 0px;
                }
                .register_question {
                    color: $gull_gray;   
                }
                .register_link { 
                    margin: 35px 0px 0px 0px;
                    color: $bahama_blue;
                    font-weight: bold;
                }
            }
    
            .button {
                border-radius: 30px;
                border: none;
                background-color: $bahama_blue;
                color: #FFFFFF;
                font-size: 18px;
                line-height: 22px;
                font-weight: bold;
                padding: 14px 0px;
                cursor: pointer;
                letter-spacing: 1px;
                width: 270px;
                margin: auto;
            }
    
        }
        .login_page_left_section {
            flex: 1;
            background: url('../../assets/background2.png') center;
            .header_title {
                color: $white;
                font-weight: bold;
                font-size: 18px;
                margin:0px;
                letter-spacing: 1px;
                padding: 20px;
                text-align: center;
            }
            .title_section {
                display: flex;
                justify-content: center;
                height: 70%;
                flex-wrap: initial;
                flex-grow: 1;
                flex-direction: column;
                align-items: center;
                color: $white;
                text-align: center;
                font-weight: bold;
                letter-spacing: 0.5px;
                .greeting_msg{
                    font-size: 20px;
                    margin: 26px auto 0px;
                }
                .greeting_title{
                    font-size: 35px;
                    margin: 26px auto 10px;
                    letter-spacing: 5px;
                }
                .secondary_text {
                    display: none;
                }
                hr {
                    width: 20%;
                    display: block;
                    height: 1px;
                    border: 0;
                    border-top: 10px solid $white;
                    margin: 1em 0;
                    padding: 0;
                }
            }
    
        }
    }
  }



// Medium screens (641px to 1024px)
@media only screen and (min-width: 640px ) and (max-width: 1024px){
    .login_page_wrapper {
        display: flex;
        .login_page_right_section {
            width: 50%;
            form {
                display: flex;
                flex-direction: column;
                min-width: 300px;
                :global(.MuiTextField-root) {
                    width: 270px;
                    margin: 10px auto;
                    &:nth-of-type(2){
                        margin-bottom: 10px;
                    }
                }
                :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline){
                    border-color: $bahama_blue;
                }
                :global(.MuiOutlinedInput-root) {
                    border-radius: 10px;
                }
                :global(.MuiOutlinedInput-input) {
                    color: $gull_gray;
                }
            }
            .login_title {
                color: $bahama_blue;
                font-weight: bold;
                font-size: 25px;
                margin: 0px;
                letter-spacing: 1px;
            }
            h4 {
                color: $gull_gray;
                letter-spacing: 1px;
            }
            .links_section {
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                color: $bahama_blue;
                margin: 0px 20px;
                .forgot_password, .privacy_policy {
                    margin-bottom: 23px;
                    color: $bahama_blue;
                }
            }
            .input {
                margin-bottom: 15px;
            }
    
            .register_section {
                display: block;
                justify-content: center;
                font-size: 15px;
                letter-spacing: 1px;
                text-align: center;
                p {
                    margin: 35px 5px 0px 0px;
                }
                .register_question {
                    color: $gull_gray;   
                }
                .register_link { 
                    margin: 35px 0px 0px 0px;
                    color: $bahama_blue;
                    font-weight: bold;
                }
            }
    
            .button {
                border-radius: 30px;
                border: none;
                background-color: $bahama_blue;
                color: #FFFFFF;
                font-size: 18px;
                line-height: 22px;
                font-weight: bold;
                padding: 14px 0px;
                cursor: pointer;
                letter-spacing: 1px;
                width: 270px;
                margin: auto;
            }
    
        }
        .login_page_left_section {
            width: 50%;
            .header_title {
                font-size: 22px;
                padding: 20px;
                text-align: center;
            }
            .title_section {
                flex-wrap: nowrap;
                .greeting_msg{
                    font-size: 22px;
                }
                .greeting_title{
                    font-size: 34px;
                }
                .secondary_text {
                    width: 370px;
                }
                hr {
                    width: 20%;
                    display: block;
                    height: 1px;
                    border: 0;
                    border-top: 10px solid $white;
                    margin: 1em 0;
                    padding: 0;
                }
            }
    
        }
    }
}


// Large screens (1008px and larger)
@media only screen and (min-width: 1008px ){
    // .login_page_wrapper {
    //     display: flex;
    //     .login_page_right_section {
    //         width: 50%;
    //         form {
    //             display: flex;
    //             flex-direction: column;
    //             min-width: 300px;
    //             :global(.MuiTextField-root) {
    //                 width: 270px;
    //                 margin: 10px auto;
    //                 &:nth-of-type(2){
    //                     margin-bottom: 10px;
    //                 }
    //             }
    //             :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline){
    //                 border-color: $curious_blue;
    //             }
    //             :global(.MuiOutlinedInput-root) {
    //                 border-radius: 10px;
    //             }
    //             :global(.MuiOutlinedInput-input) {
    //                 color: $gull_gray;
    //             }
    //         }
    //         .login_title {
    //             color: $curious_blue;
    //             font-weight: bold;
    //             font-size: 25px;
    //             margin: 0px;
    //             letter-spacing: 1px;
    //         }
    //         h4 {
    //             color: $gull_gray;
    //             letter-spacing: 1px;
    //         }
    //         .links_section {
    //             display: flex;
    //             justify-content: space-between;
    //             font-size: 15px;
    //             color: $curious_blue;
    //             margin: 0px 20px;
    //             .forgot_password, .privacy_policy {
    //                 margin-bottom: 23px;
    //                 color: $curious_blue;
    //             }
    //         }
    //         .input {
    //             margin-bottom: 15px;
    //         }
    
    //         .register_section {
    //             display: block;
    //             justify-content: center;
    //             font-size: 15px;
    //             letter-spacing: 1px;
    //             text-align: center;
    //             p {
    //                 margin: 35px 5px 0px 0px;
    //             }
    //             .register_question {
    //                 color: $gull_gray;   
    //             }
    //             .register_link { 
    //                 margin: 35px 0px 0px 0px;
    //                 color: $curious_blue;
    //                 font-weight: bold;
    //             }
    //         }
    
    //         button {
    //             border-radius: 30px;
    //             border: none;
    //             background-color: $curious_blue;
    //             color: #FFFFFF;
    //             font-size: 18px;
    //             line-height: 22px;
    //             font-weight: bold;
    //             padding: 14px 0px;
    //             cursor: pointer;
    //             letter-spacing: 1px;
    //             width: 270px;
    //             margin: auto;
    //         }
    
    //     }
    //     .login_page_left_section {
    //         width: 50%;
    //         .header_title {
    //             font-size: 22px;
    //             padding: 20px;
    //         }
    //         .title_section {
    //             flex-wrap: nowrap;
    //             .greeting_msg{
    //                 font-size: 22px;
    //             }
    //             .greeting_title{
    //                 font-size: 34px;
    //             }
    //             .secondary_text {
    //                 width: 370px;
    //             }
    //             hr {
    //                 width: 20%;
    //                 display: block;
    //                 height: 1px;
    //                 border: 0;
    //                 border-top: 10px solid $white;
    //                 margin: 1em 0;
    //                 padding: 0;
    //             }
    //         }
    
    //     }
    // }
}